import React from "react";
import Layout from "../../../../other/layout";
import HebergementWeb from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/HebergementWeb/HebergementWeb";
import SEO from "../../../../other/seo";

export default function hebergementWeb() {
  return (
    <Layout>
      <SEO title="Hebergement Web" />
      <HebergementWeb />
    </Layout>
  );
}
