import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import { Link } from "gatsby";
import hosting from "../../../../../assets/img/hosting.jpg";
import hosting_bronze from "../../../../../assets/img/hosting-bronze.gif";
import hosting_silver from "../../../../../assets/img/hosting-silver.gif";
import hosting_gold from "../../../../../assets/img/hosting-gold.gif";
import hosting_platinum from "../../../../../assets/img/hosting-platinum.gif";

export default function HebergementWeb() {
  const subMenu = header => {
    const CustomTag = header ? `th` : `td`;
    return (
      <tr>
        <CustomTag colspan="5">
          {header && <a id="top1"></a>}
          <div class={styles.modernbricksmenu}>
            <ul>
              <li>
                <a title="Design &amp; Building Tools" href="#designtools">
                  Conception graphique
                </a>
              </li>
              <li>
                <a title="Web Marketing" href="#webmarketing">
                  Marketing Web
                </a>
              </li>
              <li>
                <a title="Email Features" href="#emailfeatures">
                  Courriel
                </a>
              </li>
              <li>
                <a title="E-commerce Tools" href="#ecommerce">
                  Cybercommerce
                </a>
              </li>
              <li>
                <a title="Developer Tools" href="#developer">
                  Développement
                </a>
              </li>
              <li>
                <a title="Developer Tools" href="#top1">
                  Avec SyncSuite
                </a>
              </li>
              <li>
                <a title="Developer Tools" href="#top1">
                  Retour au début
                </a>
              </li>
            </ul>
          </div>
        </CustomTag>
      </tr>
    );
  };
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Hébergement Web</h1>
        <img
          src={hosting}
          alt="Hébergement Web"
          style={{ float: "right" }}
        ></img>
        <p>
          Deluxe Canada offre maintenant aux entrepreneurs et propriétaires de
          petits commerces canadiens des services d'hébergement Web Classe
          Affaires à prix très compétitifs. Nos services d'hébergement sont
          conçus pour répondre aux besoins toujours changeants des petits
          commerces, alors que de plus en plus d'entre eux se retrouvent sur
          Internet.
        </p>
        <h2>
          Voyez nos forfaits d'hébergement Web avec SyncSuite | Qu'est-ce que{" "}
          <Link>SyncSuite?</Link>
        </h2>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h3>Forfait de lancement</h3>
            <img src={hosting_bronze} alt="Forfait bronze"></img>
            <h4>14,99 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <ul>
              <li>Installation GRATUITE la journêe même</li>
              <li>25 adresses courriel (SOHO)</li>
              <li>
                <Link>EasySiteWizard Pro</Link>
              </li>
              <li>Iconothèque libre de droits</li>
              <li>Campagnes courriel</li>
              <li>Album de photos</li>
            </ul>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h3>Forfait professionnel standard</h3>
            <img src={hosting_silver} alt="Forfait argent"></img>
            <h4>24,99 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <p>Toutes les caractéristiques du forfait de lancement, plus :</p>
            <ul>
              <li>25 adresses courriel (Classe affaires)</li>
              <li>
                <Link>EasySiteWizard Pro</Link>
              </li>
              <li>EasyBlogBuilder</li>
              <li>Campagnes courriel</li>
              <li>EasyLiveChat</li>
            </ul>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h3>Forfait cybercommerce professionnel</h3>
            <img src={hosting_gold} alt="Forfait or"></img>
            <h4>39,99 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <p>
              Toutes les caractéristiques du forfait professionnel standard,
              plus :
            </p>
            <ul>
              <li>
                <Link>EasyStoreMaker Pro</Link>
              </li>
              <li>osCommerce</li>
              <li>EasyBlogBuilder</li>
              <li>Certificat protocole SSL</li>
              <li>EasyLiveChat</li>
            </ul>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h3>Forfait cybercommerce avancé</h3>
            <img src={hosting_platinum} alt="Forfait platine"></img>
            <h4>625,00 $ /mois</h4>
            <div className={styles.button_wrapper}>
              <Button text={"ACHETEZ MAINTENANT"} margin={"10px 0"} />
            </div>
            <p>
              Toutes les caractéristiques du forfait de cybercommerce
              professionnel, plus :
            </p>
            <ul>
              <li>50 adresses courriel (Classe affaires)</li>
              <li>
                <Link>EasySiteOptimizer</Link>
              </li>
              <li>Outils de programmation</li>
              <li>ASP, JSP, ColdFusion</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <table className={styles.tableModern}>
          <thead>{subMenu(true)}</thead>
          <tbody>
            <tr className={styles.tableHeader}>
              <td>
                <span className={styles.tableHeader}>
                  Toutes les caractéristiques
                </span>
                <br />
                <span className="style2">
                  Nos forfaits d'hébergement comprennent tout ce dont vous avez
                  besoin pour assurer la réussite de votre site Web.
                </span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Stockage sur disque</strong>
              </td>
              <td className="tiny">
                <strong>600 Mo</strong>
              </td>
              <td className="tiny">
                <strong>6 Go</strong>
              </td>
              <td className="tiny">
                <strong>6 Go</strong>
              </td>
              <td className="tiny">
                <strong>12 Go</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Transfert de données </strong>
              </td>
              <td className="tiny">
                <strong>30 Go</strong>
              </td>
              <td className="tiny">
                <strong>600 Go</strong>
              </td>
              <td className="tiny">
                <strong>600 Go</strong>
              </td>

              <td className="tiny">
                <strong>960 Go</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Comptes courriel SOHO</strong>{" "}
                <a
                  onmouseover="ddrivetip('Comptes courriel SOHO des bo\'étes aux lettres de 100 Mo dotées de toutes les caractéristiques Webmail.', 300)"
                  href="#"
                >
                  {" "}
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="tiny">
                <strong>25</strong>
              </td>
              <td className="tiny"> </td>
              <td className="tiny"> </td>
              <td className="tiny"> </td>
            </tr>
            <tr>
              <td>
                <strong>Comptes courriel classe affaires</strong>{" "}
                <a
                  onmouseover="ddrivetip('Comptes courriel Classe affaires des bo\'\étes aux lettres de 250 Mo compatibles avec le protocole IMAP permettant de conserver le courriel sur le serveur pour y accéder depuis différents endroits.', 300)"
                  href="#"
                >
                  {" "}
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="tiny"> </td>
              <td className="tiny">
                <strong>25</strong>
              </td>
              <td className="tiny">
                <strong>25</strong>
              </td>
              <td className="tiny">
                <strong>50</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  Business Mail Pro de 2 Go avec <br />
                  compte courriel SyncSuite{" "}
                </strong>{" "}
                <a
                  onmouseover="ddrivetip('Comptes courriel Classe affaires des bo\'\étes aux lettres de 250 Mo compatibles avec le protocole IMAP permettant de conserver le courriel sur le serveur pour y accéder depuis différents endroits.', 300)"
                  href="#"
                >
                  {" "}
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="tiny">
                <div className={styles.achetezLink}>
                  <a href="developpez/services-web/hebergement-web-avec-syncsuite/">
                    voir
                  </a>
                </div>
              </td>
              <td className="tiny">
                <div className={styles.achetezLink}>
                  <a href="developpez/services-web/hebergement-web-avec-syncsuite/">
                    voir
                  </a>
                </div>
              </td>
              <td className="tiny">
                <div className={styles.achetezLink}>
                  <a href="developpez/services-web/hebergement-web-avec-syncsuite/">
                    voir
                  </a>
                </div>
              </td>
              <td className="tiny">
                <div className={styles.achetezLink}>
                  <a href="developpez/services-web/hebergement-web-avec-syncsuite/">
                    voir
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Installation la journée m&amp;éme</strong>
              </td>
              <td>
                <strong>GRATUIT</strong>
              </td>
              <td>
                <strong>GRATUIT</strong>
              </td>
              <td>
                <strong>GRATUIT</strong>
              </td>
              <td>
                <strong>GRATUIT</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Temps de bon fonctionnement garanti á 99,9 %</strong>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Bande passante á la demande</strong>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Surveillance réseau 24/7 </strong>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Conception compléte de site Web offerte</strong>
              </td>
              <td>
                <span className="circle"> </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.red}>
                  {" "}
                  Quel forfait convient le mieux à vos besoins?
                  <br />
                  Laissez-nous vous aider!
                  <br />
                  Appelez SANS FRAIS...
                </strong>
                <br />
                <span className={styles.phone}>1 866 954 1230</span>
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour l'utilisateur d'un espace Web gratuit qui désire
                  éliminer les publicités.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Idéal pour ceux et celles qui débutent dans l'autoconstruction
                  de site Web et ont besoin d'un outil de création offrant des
                  résultats de grande qualité sans nécessiter d'habiletés
                  techniques.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Des modéles et des outils d'autoconstruction évolués qui
                  permettront de créer un site Web professionnel sans nécessiter
                  d'habiletés techniques ni de talent de conception.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour les concepteurs Web avancés qui désirent tirer
                  parti de nos 3400 modéles de site hautement personnalisables
                  pouvant &amp;étre édités avec des applications graphiques
                  telles que Photoshop et Flash.
                </span>
              </td>
            </tr>
            {subMenu()}
            <tr className={styles.tableHeader}>
              <td>
                <span className={styles.tableHeader}>
                  Outils de construction et de conception graphique
                </span>
                <br />
                <span className="style2">
                  Nos forfaits d'hébergement viennent avec des outils de
                  construction et de conception graphique Web faciles à
                  utiliser.
                </span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Conception de site Web :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                <a href="d-eacuteveloppez/modeles-de-sites/c-10907558-v-20060723001.htm">
                  Banque de modéles évolués
                </a>{" "}
                <a
                  onmouseover="ddrivetip('Plus de 4000 modéles évolués con&amp;éus pour les concepteurs Web avancés. Hautement personnalisables, ils peuvent \'\étre édités dans des applications graphiques telles qu\'\Adobe PhotoShop, Adobe Flash et Adobe Dreamweaver.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Iconothéque libre de droits{" "}
                <a
                  onmouseover="ddrivetip('Images libres de droits des photos qu\'\on peut utiliser gratuitement en ligne. Il s\'\agit des m\'\émes photos que celles qui sont disponibles avec EasySiteWizard, mais l\'\interface fournie par le Gestionnaire avancé de modéles permet de les télécharger directement vers le site de l\'\utilisateur.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Outils de construction de site :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                EasySiteWizard Pro{" "}
                <a
                  onmouseover="ddrivetip('EasySiteWizard Pro est un outil évolué et flexible de construction de site Web é partir de modéles. Il permet aux utilisateurs de créer des sites d\'\allure professionnelle sans connaissance du HTML. Son interface conviviale et l\'\assistant intégré offrent aux utilisateurs des fonctionnalités étendues de conception et d\'\édition, dont la possibilité de glisser-déposer des éléments avec une précision au pixel prés.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                EasyBlogBuilder{" "}
                <a
                  onmouseover="ddrivetip('EasyBlogBuilder est une application qui crée un utilitaire permettant aux utilisateurs de bloguer sur leur site Web. Le blogue que l\'\on con&amp;éoit é l\'\aide d\'\un assistant convivial peut \'\étre personnalisé avec le logo de l\'\utilisateur et les couleurs de son choix.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Album de photos{" "}
                <a
                  onmouseover="ddrivetip('L\'\outil Album photo confére é l\'\utilisateur novice ou expérimenté la possibilité de créer aisément une galerie de photos en ligne. Son interface simple, offrant une navigation facile, permet aux utilisateurs de réaliser et de publier des albums associés é des événements particuliers, puis de relier ces albums é leur site central ou bien leur attribuer une adresse indépendante.', 300)"
                  href="#"
                >
                  {" "}
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Outils de création du panier d'achat virtuel:
                </strong>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                osCommerce{" "}
                <a
                  onmouseover="ddrivetip('Commerce Ce produit présente une banque de modéles pour construire avec la populaire application osCommerce des boutiques virtuelles de facture professionnelle. Cette suite logicielle de soutien comporte aussi un assistant d\'\installation osCommerce intégré ainsi qu\'\un outil qui configure automatiquement la banque de données MYSQL de l\'\utilisateur pour qu\'\elle fonctionne avec la boutique.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                EasyStoreMaker Pro{" "}
                <a
                  onmouseover="ddrivetip('EasyStoreMaker Pro permet aux utilisateurs d\'\intégrer é leur site Web une boutique compléte pouvant traiter les commandes par carte de crédit. Nul besoin d\'\expérience préalable en programmation ou dans le développement du commerce en ligne pour mettre sur pied une boutique virtuelle avec les modéles de boutiques professionnels que vous offre EasyStoreMaker Pro. Au nombre de ses nombreuses caractéristiques, notons la compatibilité avec plusieurs passerelles de paiement, la messagerie automatique, la capacité de téléverser de multiples produits, la mise en vitrine de produits, le calcul des taxes selon la provenance de l\'\acheteur, le calcul des frais d\'\envoi et une page sécurisée oé s\'\affichent toutes les commandes en ligne.', 300)"
                  href="#"
                >
                  {" "}
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.red}>
                  {" "}
                  Quel forfait convient le mieux à vos besoins?
                  <br />
                  Laissez-nous vous aider!
                  <br />
                  Appelez SANS FRAIS...
                </strong>
                <br />1 866 954 1230
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour l'utilisateur d'un espace Web gratuit qui désire
                  éliminer les publicités.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Idéal pour ceux et celles qui débutent dans l'autoconstruction
                  de site Web et ont besoin d'un outil de création offrant des
                  résultats de grande qualité sans nécessiter d'habiletés
                  techniques.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Des modéles et des outils d'autoconstruction évolués qui
                  permettront de créer un site Web professionnel sans nécessiter
                  d'habiletés techniques ni de talent de conception.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour les concepteurs Web avancés qui désirent tirer
                  parti de nos 3400 modéles de site hautement personnalisables
                  pouvant &amp;étre édités avec des applications graphiques
                  telles que Photoshop et Flash.
                </span>
              </td>
            </tr>
            {subMenu()}
            <tr className={styles.tableHeader}>
              <td>
                <span className={styles.tableHeader}>
                  Outils de marketing Web
                </span>
                <br />
                <span className="style2">
                  Nos forfaits d'hébergement comprennent des outils pour la mise
                  en marché et la promotion de votre site Web.{" "}
                </span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Suivi de la fréquentation du site et publicité :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                {" "}
                Statistiques Web{" "}
                <a
                  onmouseover="ddrivetip('Statistiques Web est un programme d\'\analyse des fichiers journaux du serveur Web. Il produit des statistiques d\'\utilisation é partir des fichiers journaux du serveur hébergeant le site de l\'\utilisateur, fournissant ainsi des renseignements détaillés sur le trafic et la provenance des visiteurs.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Gestion des fichiers d'activité{" "}
                <a
                  onmouseover="ddrivetip('Le Gestionnaire de fichiers journaux permet é l\'\utilisateur final d\'\obtenir des journaux de serveur bruts au sujet de l\'\activité et du trafic sur son site. Il a alors la possibilité de visualiser, supprimer, imprimer, télécharger ou dater ces fichiers. Il peut aussi désactiver ces enregistrements afin de conserver de l\'\espace disque.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Outils de référencement :
                </strong>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                {" "}
                EasySiteOptimizer{" "}
                <a
                  onmouseover="ddrivetip('EasySiteOptimizer est un outil d\'\optimalisation de site pour moteurs de recherche. L\'\application par navigateur intelligente offre une suite d\'\outils logiciels permettant de choisir des mots clés afin d\'\indexer les pages du site, d\'\en analyser le contenu et la disposition et de les soumettre aux moteurs de recherche les plus populaires.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Assistant de promotion
                <a
                  onmouseover="ddrivetip('Le Promoteur de site est un utilitaire de base qui permet é l\'\utilisateur de soumettre facilement son site é des moteurs de recherche choisis afin d\'\obtenir un meilleur positionnement dans les résultats de recherche. Note : EasySiteOptimizer, offert avec les forfaits avancés, permet également de soumettre un site optimisé aux moteurs de recherche.', 300)"
                  href="#"
                >
                  {" "}
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Outils de communication pour visiteurs:
                </strong>
              </td>
              <td> </td>

              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                EasyLiveChat{" "}
                <a
                  onmouseover="ddrivetip('EasyLiveChat permet é l\'\utilisateur d\'\ajouter é son site un systéme de clavardage pour offrir du soutien technique et répondre aux demandes de renseignements généraux. On peut configurer le systéme pour quatre agents de clavardage (au maximum), chacun pouvant entretenir jusqué quatre sessions de clavardage simultanément. EasyLiveChat est doté de nombreuses caractéristiques qui permettent l\'\augmentation de la productivité, notamment. Suivi en temps ré PURL et messages prédéfinis éditables. Sondages des clients et rapports. Signaux sonores et visuels. Transcriptions des sessions de clavardage entiérement interrogeables.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Announcer Pro{" "}
                <a
                  onmouseover="ddrivetip('Announcer Pro facilite la création d\'\infolettres courriel et la gestion des communications réguliéres avec vos clients. De puissants outils de conception utilisent le code HTML pour créer des courriels attrayants dotés de la flexibilité graphique d\'\une page Web. Avec Announcer Pro, l\'\utilisateur peut gérer des listes de distribution d\'\adresses courriel qui faciliteront l\'\envoi de messages é un groupe déterminé de destinataires.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>50 adresses courriel/mois</td>
              <td>50 adresses courriel/mois</td>
              <td>50 adresses courriel/mois</td>
            </tr>
            <tr>
              <td>
                <strong className={styles.red}>
                  {" "}
                  Quel forfait convient le mieux à vos besoins?
                  <br />
                  Laissez-nous vous aider!
                  <br />
                  Appelez SANS FRAIS...
                </strong>
                <br />1 866 954 1230
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour l'utilisateur d'un espace Web gratuit qui désire
                  éliminer les publicités.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Destiné aux débutants qui souhaitent évaluer l'achalandage de
                  leur site Web.{" "}
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Pour les gestionnaires d'entreprises qui veulent bétir et
                  étendre leur clientéle par un suivi étroit de l'achalandage de
                  leur site et un bon référencement dans les moteurs de
                  recherche.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Créé pour les propriétaires de sites Web avancés désireux
                  d'effectuer le suivi simultané de plusieurs campagnes de
                  publicité et d'augmenter leurs ventes par le marketing en
                  ligne.{" "}
                </span>
              </td>
            </tr>
            {subMenu()}
            <tr className={styles.tableHeader}>
              <td>
                <span className={styles.tableHeader}>
                  Caractéristiques courriel
                </span>
                <br />
                <span className="style2">
                  Nos forfaits d'hébergement proposent des comptes courriel aux
                  caractéristiques qui sauront combler les besoins les plus
                  complexes en matiére de communication.
                </span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Caractéristiques de base :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                <div>
                  Comptes courriel SOHO{" "}
                  <a
                    onmouseover="ddrivetip('Comptes courriel SOHO des bo\'\étes aux lettres de 100 Mo dotées de toutes les caractéristiques Webmail.', 300)"
                    href="#"
                  >
                    <span
                      className="material-icons"
                      style={{ color: "#155a7e" }}
                    >
                      info
                    </span>
                  </a>
                </div>
              </td>
              <td className="tiny">
                <strong>25</strong>
              </td>
              <td className="tiny"> </td>
              <td className="tiny"> </td>
              <td className="tiny"> </td>
            </tr>
            <tr>
              <td>
                <div>
                  Comptes courriel classe affaires{" "}
                  <a
                    onmouseover="ddrivetip('Comptes courriel Classe affaires des bo\'\étes aux lettres de 250 Mo compatibles avec le protocole IMAP permettant de conserver le courriel sur le serveur pour y accéder depuis différents endroits.', 300)"
                    href="#"
                  >
                    <span
                      className="material-icons"
                      style={{ color: "#155a7e" }}
                    >
                      info
                    </span>
                  </a>
                </div>
              </td>
              <td className="tiny"> </td>
              <td className="tiny">
                <strong>25</strong>
              </td>
              <td className="tiny">
                <strong>25</strong>
              </td>
              <td className="tiny">
                <strong>50</strong>
              </td>
            </tr>
            <tr>
              <td>Taille d'une bo&amp;éte aux lettres individuelle</td>
              <td className="tiny">
                <strong>1 Go</strong>
              </td>
              <td className="tiny">
                <strong>1 Go</strong>
              </td>
              <td className="tiny">
                <strong>1 Go</strong>
              </td>
              <td className="tiny">
                <strong>1 Go</strong>
              </td>
            </tr>
            <tr>
              <td>Compte courriel supplémentaire</td>
              <td className="tiny">0,99 $/mois</td>
              <td className="tiny">0,99 $/mois</td>
              <td className="tiny">0,99 $/mois</td>
              <td className="tiny">0,99 $/mois</td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Caractéristiques supplémentaires :
                </strong>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                Antivirus{" "}
                <a
                  onmouseover="ddrivetip('les comptes EasyMailMC bénéficient tous d\'\un systéme de détection des virus. Tous les messages entrants et sortants ainsi que les piéces jointes sont scrutés et ne seront relayés é leur destinataire que s\'\ils sont exempts de virus.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                IMAP (courriel conservé sur le serveur){" "}
                <a
                  onmouseover="ddrivetip('IMAP (courriel conservé sur le serveur) est une méthode permettant d\'\accéder aux messages courriel sur le serveur sans avoir é les télécharger sur le disque dur de son ordinateur.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                POP et SMTP{" "}
                <a
                  onmouseover="ddrivetip('Les protocoles POP et SMTP POP3 requiert de l\'\utilisateur qu\'\il télécharge ses messages courriel sur le disque dur de son ordinateur avant de les lire. SMTP est le protocole utilisé pour envoyer le courriel via Internet.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Webmail{" "}
                <a
                  onmouseover="ddrivetip('Webmail est un systéme courriel personnalisable doté de multiples caractéristiques et dont l\'\utilisation est indépendante des navigateurs. Parmi ses nombreuses caractéristiques, mentionnons : le calendrier en ligne, les listes de téches, la gestion avancée du carnet d\'\adresses, la gestion de fichiers personnels, la configuration autorépondeur, le vérificateur d\'\épellation et le formatage HTML.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Filtre antipourriel{" "}
                <a
                  onmouseover="ddrivetip('Filtre antipourriel la fonction filtre antipourriel d\'\EasyMail permet é l\'\utilisateur d\'\emp\'\écher les courriels non sollicités de parvenir é sa bo\'\éte aux lettres. En activant le filtre antipourriel d\'\un compte, chaque courriel qui entre dans la bo\'\éte de réception est évalué par un filtre automatique et re&amp;éoit un pointage en fonction du contenu de l\'\en-t\'\éte et du corps du message. Si le pointage total dépasse une certaine valeur, il est alors catégorisé comme pourriel. L\'\objet, le formatage et les en-t\'\étes du message sont tous examinés afin de déterminer si celui-ci présente les caractéristiques d\'\un pourriel. L\'\utilisateur a le choix entre les trois actions suivantes pour traiter les messages ainsi interceptés : marquage et livraison, suppression ou mise en quarantaine.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                EasyMailMD Set-up{" "}
                <a
                  onmouseover="ddrivetip('EasyMail Setup est un outil complet qui permet é n\'\importe quel utilisateur novice de gérer facilement les comptes courriel associés é son domaine et d\'\en configurer é sa guise les différentes caractéristiques. On accéde é ces comptes soit par le biais des gestionnaires de courriel traditionnels, soit sur internet avec Webmail.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Gestionnaire de courriel personnel{" "}
                <a
                  onmouseover="ddrivetip('La fonction Courriel personnel permet aux propriétaires de comptes courriel d\'\administrer différents paramétres de ceux-ci sans avoir é se connecter é WebsiteOS, par exemple configurer la fonction de redirection, le filtre antipourriel et l\'\autorépondeur, ou encore modifier le mot de passe d\'\un compte.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                Autorépondeur{" "}
                <a
                  onmouseover="ddrivetip('Autorépondeur permet é l\'\utilisateur de répondre automatiquement par un message prérédigé é tout ou partie des messages envoyés é une adresse courriel. On emploie le plus couramment cette fonction pour avertir les expéditeurs lorsqu\'\on s\'\absente du bureau pour quelques jours.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Announcer Pro{" "}
                <a
                  onmouseover="ddrivetip('Announcer Pro facilite la création d\'\infolettres courriel et la gestion des communications réguli'egrave;res avec vos clients. De puissants outils de conception utilisent le code HTML pour créer des courriels attrayants dotés de la flexibilité graphique d\'\une page Web. Avec Announcer Pro, l\'\utilisateur peut gérer des listes de distribution d\'\adresses courriel qui faciliteront l\'\envoi de messages é un groupe déterminé de destinataires.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>50 adresses courriel/mois</td>
              <td>50 adresses courriel/mois</td>
              <td>50 adresses courriel/mois</td>
            </tr>
            <tr>
              <td>
                Alias collecteur pour un nombre illimité <br />
                d'adresses courriel{" "}
                <a
                  onmouseover="ddrivetip('Alias collecteur. Cette fonction permet é l\'\utilisateur de rediriger vers un alias collecteur les messages courriel mal adressés envoyés é leur domaine. Par message courriel mal adressé, on entend tout courriel envoyé au domaine d\'\un utilisateur vers un compte courriel non défini. Par exemple, si un client posséde le domaine mavoiture.com mais n\'\a pas défini de compte courriel pour facture@mavoiture.com avec EasyMail Setup, tout courriel envoyé é l\'\adresse facture@mavoiture.com est considéré comme mal adressé et sera redirigé vers l\'\alias collecteur.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Partage de calendriers
                <strong>
                  {" "}
                  <a href="d-eacuteveloppez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/c-10907526-v-20060723001.htm">
                    SyncSuite
                  </a>
                </strong>{" "}
                <a
                  onmouseover="ddrivetip('Business Mail Pro en association avec SyncSuite permet é l\'\utilisateur de consulter les calendriers, carnets d\'\adresses, listes de téches et notes de ses collégues par le biais de Webmail. Tous les usagers ont la possibilité de partager leurs calendriers, carnet d\'\adresses, liste de téches et notes personnelles é travers leur domaine. Facile de distinguer les calendriers personnels de ceux d\'\une division ou d\'\un service, car le systéme permet d\'\attribuer une couleur différente é chacun lorsqu\'\on en consulte plusieurs dans Webmail.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td>
                <div className="achetez-link info">
                  <a href="developpez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/">
                    d'info
                  </a>
                </div>
              </td>
              <td>
                <div className="achetez-link info">
                  <a href="developpez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/">
                    d'info
                  </a>
                </div>
              </td>
              <td>
                <div className="achetez-link info">
                  <a href="developpez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/">
                    d'info
                  </a>
                </div>
              </td>
              <td>
                <div className="achetez-link info">
                  <a href="developpez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/">
                    d'info
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Partage de carnets d'adresses{" "}
                <strong>
                  <a href="d-eacuteveloppez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/c-10907526-v-20060723001.htm">
                    SyncSuite
                  </a>
                </strong>{" "}
                <a
                  onmouseover="ddrivetip('Business Mail Pro en association avec SyncSuite permet é l\'\utilisateur de consulter les calendriers, carnets d\'\adresses, listes de téches et notes de ses collégues par le biais de Webmail. Tous les usagers ont la possibilité de partager leurs calendriers, carnet d\'\adresses, liste de téches et notes personnelles é travers leur domaine. Facile de distinguer les calendriers personnels de ceux d\'\une division ou d\'\un service, car le systéme permet d\'\attribuer une couleur différente é chacun lorsqu\'\on en consulte plusieurs dans Webmail.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Partage de notes{" "}
                <strong>
                  <a href="d-eacuteveloppez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/c-10907526-v-20060723001.htm">
                    SyncSuite
                  </a>
                </strong>{" "}
                <a
                  onmouseover="ddrivetip('Business Mail Pro en association avec SyncSuite permet é l\'\utilisateur de consulter les calendriers, carnets d\'\adresses, listes de téches et notes de ses collégues par le biais de Webmail. Tous les usagers ont la possibilité de partager leurs calendriers, carnet d'adresses, liste de téches et notes personnelles é travers leur domaine. Facile de distinguer les calendriers personnels de ceux d\'\une division ou d\'\un service, car le systéme permet d\'\attribuer une couleur différente é chacun lorsqu\'\on en consulte plusieurs dans Webmail.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Partage de listes de téches{" "}
                <strong>
                  <a href="d-eacuteveloppez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/c-10907526-v-20060723001.htm">
                    SyncSuite
                  </a>
                </strong>{" "}
                <a
                  onmouseover="ddrivetip('Business Mail Pro en association avec SyncSuite permet é l\'\utilisateur de consulter les calendriers, carnets d\'\adresses, listes de téches et notes de ses collégues par le biais de Webmail. Tous les usagers ont la possibilité de partager leurs calendriers, carnet d\'\adresses, liste de téches et notes personnelles é travers leur domaine. Facile de distinguer les calendriers personnels de ceux d\'\une division ou d\'\un service, car le systéme permet d\'\attribuer une couleur différente é chacun lorsqu\'\on en consulte plusieurs dans Webmail.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Accés{" "}
                <strong>
                  <a href="d-eacuteveloppez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/c-10907526-v-20060723001.htm">
                    SyncSuite
                  </a>
                </strong>{" "}
                à partir de tout ordinateur de bureau. Clients : Outlook,
                Outlook Express{" "}
                <a
                  onmouseover="ddrivetip('Carnets d\'\adresses et calendriers sont synchronisés par Internet en tout temps et en tous lieux, ce qui vous assure d\'\obtenir sur n\'\importe quel de vos dispositifs une information juste et é jour.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                Accés{" "}
                <a href="d-eacuteveloppez/services-web/forfaits-courriels/business-mail-pro-avec-syncsuite/c-10907526-v-20060723001.htm">
                  SyncSuite
                </a>{" "}
                à partir de tout terminal mobile de poche. Clients : Blackberry
                et Palm{" "}
                <a
                  onmouseover="ddrivetip('Business Mail Pro en association avec SyncSuite garantissent un traitement fidéle de vos entrées manuelles : un simple clic lance la synchronisation de vos données et leur téléversement vers vos différents dispositifs.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.red}>
                  {" "}
                  Quel forfait convient le mieux à vos besoins?
                  <br />
                  Laissez-nous vous aider!
                  <br />
                  Appelez SANS FRAIS...
                </strong>
                <br />1 866 954 1230
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour l'utilisateur d'un espace Web gratuit qui désire
                  éliminer les publicités.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Créé pour les petites entreprises qui ont besoin des services
                  courriel complets tels que ceux qu'offre Webmail.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Créé pour les petites entreprises qui ont besoin de services
                  courriel complets tels que ceux qu'offre Webmail.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Une solution solide permettant de combler entiérement les
                  besoins de gestion du courriel d'affaires des entreprises dont
                  le site est trés achalandé.
                </span>
              </td>
            </tr>
            {subMenu()}
            <tr className={styles.tableHeader}>
              <td>
                <span className={styles.tableHeader}>
                  Caractéristiques des fonctions de cybercommerce
                </span>
                <br />
                <span className="style2">
                  Fonctions incluses avec certains de nos forfaits
                  d'hébergement.
                </span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>Taille de la boutique :</strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td> Nombre maximum de produits</td>
              <td className="tiny"> </td>
              <td className="tiny"> </td>
              <td className="tiny">
                <strong>Illimité</strong>
              </td>
              <td className="tiny">
                <strong>Illimité</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>Paiement et sécurité :</strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                Serveur sécurisé certifié (protocole SSL){" "}
                <a
                  onmouseover="ddrivetip('SSL est l\'\abréviation de \'\é Secure Sockets Layer \'\é, en fran&amp;éais \'\é Serveur sécurisé certifié. Le protocole SSL crypte é l\'\aide d\'\une clé confidentielle les données transférées par le biais d\'\une connexion SSL. Plusieurs sites Web utilisent ce protocole pour recueillir des informations confidentielles telles que les numéros de carte de crédit. Par convention, le préfixe https indique que l\'\URL est sécurisé. L\'\application Gestionnaire SSL assiste l\'\utilisateur dans l\'\installation du systéme de sécurisation SSL pour son site Web. Elle lui offre le choix entre notre certificat SSL générique ou la création de son propre certificat.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Cartes de crédit acceptées en ligne (en temps réel){" "}
                <a
                  onmouseover="ddrivetip('Traitement des cartes de crédit. Cette application indique que le forfait d\'\hébergement offre le traitement en temps réel des achats par carte de crédit effectués dans un cybercommerce et que la sécurité des transactions est garantie.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Outils de création du panier d'achat virtuel :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                osCommerce{" "}
                <a
                  onmouseover="ddrivetip('Commerce Ce produit présente une banque de modéles pour construire avec la populaire application osCommerce des boutiques virtuelles de facture professionnelle. Cette suite logicielle de soutien comporte aussi un assistant d\'\installation osCommerce intégré ainsi qu\'\un outil qui configure automatiquement la banque de données MYSQL de l\'\utilisateur pour qu\'\elle fonctionne avec la boutique.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                EasyStoreMaker Pro{" "}
                <a
                  onmouseover="ddrivetip('EasyStoreMaker Pro permet aux utilisateurs d\'\intégrer é leur site Web une boutique compléte pouvant traiter les commandes par carte de crédit. Nul besoin d\'\expérience préalable en programmation ou dans le développement du commerce en ligne pour mettre sur pied une boutique virtuelle avec les modéles de boutiques professionnels que vous offre EasyStoreMaker Pro. Au nombre de ses nombreuses caractéristiques, notons la compatibilité avec plusieurs passerelles de paiement, la messagerie automatique, la capacité de téléverser de multiples produits, la mise en vitrine de produits, le calcul des taxes selon la provenance de l\'\acheteur, le calcul des frais d\'\envoi et une page sécurisée oé s\'\affichent toutes les commandes en ligne.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Communication avec la clientéle :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                EasyLiveChat{" "}
                <a
                  onmouseover="ddrivetip('EasyLiveChat permet é l\'\utilisateur d\'\ajouter é son site un systéme de clavardage pour offrir du soutien technique et répondre aux demandes de renseignements généraux. On peut configurer le systéme pour quatre agents de clavardage (au maximum), chacun pouvant entretenir jusqué quatre sessions de clavardage simultanément. EasyLiveChat est doté de nombreuses caractéristiques qui permettent l\'\augmentation de la productivité, notamment. Suivi en temps ré PURL et messages prédéfinis éditables. Sondages des clients et rapports. Signaux sonores et visuels. Transcriptions des sessions de clavardage entiérement interrogeables', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.red}>
                  {" "}
                  Quel forfait convient le mieux à vos besoins?
                  <br />
                  Laissez-nous vous aider!
                  <br />
                  Appelez SANS FRAIS...
                </strong>
                <br />1 866 954 1230
              </td>
              <td>
                <span className="tiny_blue">
                  Parfait pour l'utilisateur d'un espace Web gratuit qui désire
                  éliminer les publicités.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Créé pour les petites entreprises désirant un site internet
                  sophistiqué pour attirer de nouveaux clients.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Pour les propriétaires de cyberentreprises qui veulent une
                  boutique en ligne offrant la possibilité de vendre un nombre
                  illimité de produits.
                </span>
              </td>
              <td>
                <span className="tiny_blue">
                  Créé pour les besoins du cybercommerce avancé : certificat
                  protocole SSL, traitement en temps réel des achats et
                  applications avancées de construction de boutiques virtuelles
                  telles que OSCommerce.
                </span>
              </td>
            </tr>
            {subMenu()}
            <tr className={styles.tableHeader}>
              <td>
                <span className={styles.headings}>
                  Caractéristiques des fonctions de développement
                </span>
                <br />
                <span className="style2">
                  Nos forfaits d'hébergement comprennent des outils faciles à
                  utiliser pour la construction de site et la conception
                  graphique Web.
                </span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Caractéristiques de base :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                <strong>Stockage sur disque</strong>
              </td>
              <td className="tiny">
                <strong className="tiny">300 Mo</strong>
              </td>
              <td className="tiny">
                <strong>3 Go</strong>
              </td>
              <td className="tiny">
                <strong>3 Go</strong>
              </td>
              <td className="tiny">
                <strong>6 Go</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Transfert de données</strong>
              </td>
              <td className="tiny">
                <strong className="tiny">15 Go</strong>
              </td>
              <td className="tiny">
                <strong>300 Go</strong>
              </td>
              <td className="tiny">
                <strong>300 Go</strong>
              </td>
              <td className="tiny">
                <strong>480 Go</strong>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Systémes d'exploitation supportés</strong>
              </td>
              <td>
                <img
                  height="14"
                  border="0"
                  width="14"
                  alt="Linux system"
                  src="/store/20060723001/assets/templateimages/linux.png"
                />
              </td>
              <td>
                <img
                  height="14"
                  border="0"
                  width="14"
                  alt="Linux system"
                  src="/store/20060723001/assets/templateimages/linux.png"
                />
              </td>
              <td>
                <img
                  height="14"
                  border="0"
                  width="14"
                  alt="Linux system"
                  src="/store/20060723001/assets/templateimages/linux.png"
                />
              </td>
              <td>
                <img
                  height="13"
                  border="0"
                  width="13"
                  alt="Windowns system"
                  src="/store/20060723001/assets/templateimages/os_win.gif"
                />{" "}
                <span className="tiny">or</span>{" "}
                <img
                  height="14"
                  border="0"
                  width="14"
                  alt="Linux system"
                  src="/store/20060723001/assets/templateimages/linux.png"
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>Gestion des comptes :</strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                Serveur sécurisé certifié (protocole SSL){" "}
                <a
                  onmouseover="ddrivetip('SSL est l\'\abréviation de \'\é Secure Sockets Layer \'\é, en fran&amp;éais \'\é Serveur sécurisé certifié \'\é. Le protocole SSL crypte é l\'\aide d\'\une clé confidentielle les données transférées par le biais d\'\une connexion SSL. Plusieurs sites Web utilisent ce protocole pour recueillir des informations confidentielles telles que les numéros de carte de crédit. Par convention, le préfixe https indique que l\'\URL est sécurisé. L\'\application Gestionnaire SSL assiste l\'\utilisateur dans l\'\installation du systéme de sécurisation SSL pour son site Web. Elle lui offre le choix entre notre certificat SSL générique ou la création de son propre certificat. ', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Protocole sécurisé SSH{" "}
                <a href="#">
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Gestionnaire de DNS{" "}
                <a
                  onmouseover="ddrivetip('Le Gestionnaire de DNS permet é l\'\utilisateur d\'\éditer le fichier de zone de son domaine et de modifier différentes entrées, notamment celles du \'\é A record \'\é (adresse), \'\é CNAME record \'\é (nom canonique) et \'\é MX record \'\é (messager).', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Restauration de fichier{" "}
                <a
                  onmouseover="ddrivetip('Restauration de fichier permet é l\'\utilisateur de restaurer le contenu de son site Web tel qu\'\il était é différents moments précis antérieurement (une heure plus t\'\ét, un jour plus t\'\ét, etc.). Bien que l\'\on recommande é l\'\utilisateur de toujours conserver une copie physique distincte du contenu de son site, Restauration de fichier constitue une méthode pratique pour restituer des éléments de ce contenu sans avoir recours aux copies de sauvegarde.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Gestion de fichiers journaux{" "}
                <a
                  onmouseover="ddrivetip('Gestionnaire de fichiers journaux permet é l\'\utilisateur final d\'\obtenir des journaux de serveur bruts au sujet de l\'\activité et du trafic sur son site. Il a alors la possibilité de visualiser, supprimer, imprimer, télécharger ou dater ces fichiers. Il peut aussi désactiver ces enregistrements afin de conserver de l\'\espace disque.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Gestionnaire FTP{" "}
                <a
                  onmouseover="ddrivetip('FTP est un protocole de communication qui permet un transfert facile, efficace et précis de fichiers d\'\un ordinateur é un autre é travers un réseau ou, dans ce cas-ci, de l\'\ordinateur d\'\un utilisateur final é un serveur Web é travers Internet. Le Gestionnaire FTP est une fonction qui permet é l\'\utilisateur de contr\'\éler l\'\acc\'\egrave;s é des répertoires FTP particuliers ou au FTP anonyme.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Compteur d'utilisation du disque{" "}
                <a
                  onmouseover="ddrivetip('L\'\utilisation du disque est la quantité d\'\espace du répertoire central dont dispose l\'\utilisateur. Le Compteur d\'\utilisation du disque, qui calcule l\'\espace occupé en kilo-octets (ko), affiche le nombre total de répertoires é l\'\intérieur de l\'\espace virtuel du domaine. Il affiche également en entier les chemins d\'\accés aux répertoires qui contiennent des fichiers.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Gestionnaire de fichiers{" "}
                <a
                  onmouseover="ddrivetip('Gestionnaire de fichiers confére é l\'\utilisateur la capacité de prendre le plein contr\'\éle des fichiers de son site Web. L\'\utilisateur expérimenté privilégiera probablement cette option s\'\ére é l\'\accés complet au shell. Gestionnaire de fichiers lui permet de créer ou de retirer des répertoires, de copier, déplacer, supprimer, renommer ou éditer des fichiers, d\'\en modifier les autorisations ou encore de les téléverser de son ordinateur au serveur. Il est idéal pour manipuler des fichiers ayant été préalablement téléversés sur le site de l\'\utilisateur. Pour téléverser plus d\'\un fichier é la fois, il est généralement plus efficace de recourir é un client FTP', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected">
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Technologies supportées :
                </strong>
              </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                ColdFusion{" "}
                <a
                  onmouseover="ddrivetip('ColdFusion est un serveur d\'\applications Web complet qui fournit ou permet de développer des applications extensibles de cybercommerce. La solution ColdFusion se compose de deux ensembles interreliés: ColdFusion Studio -- étroitement intégré é ColdFusion Server, ColdFusion Studio offre des outils de débogage, de programmation visuelle et d\'\opération de base de données pour bétir des applications Web sophistiquées. ColdFusion Server fournit tous les exécuteurs nécessaires pour doter le cybercommerce de l\'\utilisateur d\'\applications forgées sur une architecture ouverte et hautement extensible.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                ASP{" "}
                <a
                  onmouseover="ddrivetip('Microsoft ASP (pages de serveur actif) est une technologie de script c\'\été serveur qui peut \'\étre employée pour créer des applications Web dynamiques et interactives. Une page ASP est une page HTML contenant des scripts c\'\été serveur qui sont traités par un serveur Web avant d\'\étre envoyés au navigateur de l\'\utilisateur. Il est possible de combiner aux pages ASP du langage XML et HTML pour créer des sites interactifs puissants.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                JSP{" "}
                <a
                  onmouseover="ddrivetip('JSP (pages de serveur JAVA) est une technologie qui permet de régler le contenu ou l\'\apparence de pages Web par l\'\intermédiaire de servlets, de petits programmes définis é m\'\éme une page et qui s\'\exécutent sur le serveur Web pour la modifier avant de l\'\envoyer é l\'\utilisateur qui en a fait la requ\'\éte.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                PHP{" "}
                <a
                  onmouseover="ddrivetip('PHP est un langage de script c\'\été serveur. Les commandes PHP, enchéssées dans le HTML d\'\une page, s\'\exécutent sur le serveur Web pour générer des pages HTML dynamiques.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Perl{" "}
                <a
                  onmouseover="ddrivetip('PERL (Practical Extraction and Reporting Language) est un langage de programmation souvent employé pour créer des programmes CGI.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                CGI Bin{" "}
                <a href="#">
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>
                  Soutien de bases de données :
                </strong>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                MySQL{" "}
                <a href="#">
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                MS Access (Microsoft Access){" "}
                <a href="#">
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Serveur SQL Microsoft{" "}
                <a
                  onmouseover="ddrivetip('MS-SQL (Microsoft SQL) est une plateforme compléte d\'\opération de base de données offrant une gestion de données adaptée é l\'\entreprise et des outils intégrés de veille stratégique. La richesse du XML combinée é la compatibilité avec la norme Internet rend faciles le stockage et la récupération des données au format XML par le biais de procédures de stockage intégrées. L\'\utilisateur peut aussi employer la structure de mise é jour de données XML pour insérer, actualiser et supprimer aisément des données.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
                <br />
              </td>
            </tr>
            <tr>
              <td>
                Gestionnaire de base de données{" "}
                <a
                  onmouseover="ddrivetip('Le Gestionnaire de base de données est une application Web MySQL qui permet é l\'\utilisateur de créer et de manipuler jusqu\'é deux bases de données MySQL. L\'\utilisateur qui a besoin de plus de deux bases privilégiera un autre systéme tel que MS Access ou MS SQL. Ce gestionnaire est également idéal pour créer des tableaux. L\'\utilisation d\'\un script (PHP, ColdFusion) ou de CGI est recommandée pour charger une base de données.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.blue}>Outils de webmestre :</strong>
              </td>
              <td className="selected"> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </tr>
            <tr>
              <td>
                Sécurité de site Web{" "}
                <a
                  onmouseover="ddrivetip('Seécurité de site Web est une application qui restreint l\'\accés é certaines zones du site Web de l\'\utilisateur. Ces zones sont protégées par un nom d\'\h\'\éte, une adresse IP ou un nom d\'\usager et un mot de passe. Un nom d\'\h\'\éte est un nom unique qui identifie un ordinateur sur un réseau et que l\'\on appelle également nom de site. L\'\adresse IP, abréviation d\'\Internet Protocol, identifie l\'\expéditeur et le destinataire des informations circulant sur Internet.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
                <br />
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                FormMail{" "}
                <a
                  onmouseover="ddrivetip('FormMail.Intégré au programme de scripts du module d\'\extension, FormMail envoie automatiquement par courriel, é l\'\adresse spécifiée par l\'\utilisateur du site, les renseignements tapés lors du remplissage d\'\un formulaire.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
                <br />
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Compteur de visites{" "}
                <a
                  onmouseover="ddrivetip('Compteur de visites. Intégré au programme de scripts du module d\'\extension, le Compteur de visites dénombre les personnes consultant le site d\'\un utilisateur chaque jour. Il illustre par un graphique le nombre d\'\appels de fichier que le site re&amp;éoit. L\'\utilisateur a le choix d\'\afficher ou de masquer le compteur.', 300)"
                  href="#"
                >
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
                <br />
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                Vérificateur de site{" "}
                <a href="#">
                  <span className="material-icons" style={{ color: "#155a7e" }}>
                    info
                  </span>
                </a>
                <br />
              </td>
              <td className="selected"> </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
              <td>
                <span className="material-icons" style={{ color: "#155a7e" }}>
                  check
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <strong className={styles.red}>
                  {" "}
                  Quel forfait convient le mieux à vos besoins?
                  <br />
                  Laissez-nous vous aider!
                  <br />
                  Appelez SANS FRAIS...
                </strong>
                <br />
                <span>1 866 954 1230</span>
              </td>
              <td>
                <strong className={styles.blue}>
                  Forfait de <br />
                  lancement
                </strong>
                <br />
                <span className={styles.price}>14,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Professionnel <br />
                  standard
                </strong>
                <br />
                <span className={styles.price}>24,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mBRONZE2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  professionnel
                </strong>
                <br />
                <span className={styles.price}>39,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mWESILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
              <td>
                <strong className={styles.blue}>
                  Cybercommerce <br />
                  avancé
                </strong>
                <br />
                <span className={styles.price}>49,99 $</span>
                <div>/mois</div>
                <div className={styles.achetezLink}>
                  <a href="http://manage.nebshosting.ca/cgi-bin/wairs/hc.signup.cgi?newpackage=mSILVER2207&amp;languageSelect=fr">
                    Achetez Maintenant
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="5"> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
